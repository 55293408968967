<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title>
            คืน
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-date v-model="Recieve.RecieveDate"></v-date>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  label="จำนวนเงิน"
                  placeholder="จำนวนเงิน"
                  dense
                  type="number"
                  v-model="Recieve.RecieveTotal"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  label="รายละเอียด"
                  placeholder="รายละเอียด"
                  dense
                  v-model="Recieve.RecieveRemark"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="1" lg="1">
                <v-btn color="primary" width="100%" @click="Insert()">
                  <v-icon>fa fa-save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title>
            ประวัติ
          </v-card-title>
          <v-data-table
            :items-per-page="-1"
            :headers="headers"
            :items="desserts"
            class="elevation-1"
          >
            <template v-slot:item.action="{ item }">
              <v-btn class="mx-2 red" fab dark small @click="Delete(item)">
                <v-icon>fa fa-trash-alt</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      Recieve: {
        UserId: this.$route.query.UserId,
        RecieveTotal: "0",
        RecieveRemark: "",
        RecieveDate: "",
      },
      UserId: this.$route.query.UserId,
      desserts: [],
      headers: [
        {
          text: "วันที่",
          align: "left",
          sortable: true,
          value: "RecieveDate",
        },
        {
          text: "รายละเอียด",
          align: "left",
          sortable: true,
          value: "RecieveRemark",
        },
        {
          text: "จำนวนเงิน",
          align: "left",
          sortable: true,
          value: "RecieveTotal",
          width: 100,
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select(this.UserId);
    });
  },
  methods: {
    Insert() {
      axios.post("/api/v1/Insert/insert/Recieve", this.Recieve).then((res) => {
        if (res.status == 200) {
          this.Select(this.UserId);
          this.Prints(res.data)
        }
      });
    },
    Select(v) {
      axios.get("/api/v1/select/select/Recieve?UserId=" + v).then((res) => {
        if (res.status == 200) {
          this.desserts = res.data;
        }
      });
    },
    Delete(v) {
      this.PassAccept("delete", () => {
        axios
          .delete("/api/v1/delete/delete/Recieve?RecieveId=" + v.RecieveId)
          .then((res) => {
            if (res.status == 200) {
              this.Select(this.UserId);
            }
          });
      });
    },
    Prints(v){
      axios.get(`/api/v1/FinanceReceive/pdf?Id=${v.Id}`).then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
    },
  },
};
</script>
